import clsx from 'clsx';
import { useRouter } from 'next/router';
import { ReactElement } from 'react';
import {
  useFileGuestReportMutation,
  useFileReportMutation,
} from '@/generated/graphql';
import { useMe } from '@/hooks';
import { GTMEvent, trackGTMEvent } from '@/hooks/use-google-tag-manager';
import { JSONValue } from '@/types/json';
import { getRouteForEditReport, getRouteForReport } from '@/types/routes';
import { ReportSource } from '@/types/scam-report-source';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { postAuthQueryParam } from '@/hooks/auth/useHandlePostAuth';
import { ScamReportDetailsLoading } from '../scam-report-details';
import { usePopUpOnError } from '../global-error-notifier';
import { ReportForm } from './components/report-form';
import { FormValuesFromReportFragment, SubmitReportFormValues } from './types';
import {
  clearReportFormLocalState,
  getReportFormLocalState,
} from './components/report-form/utils';

export type FileReportPageProps = StyleProps & {
  /** Handler to call when user files report */
  onFileReport: (props: SubmitReportFormValues) => void;

  /** Whether the form is loading */
  isSubmitting?: boolean;
};

const ROOT = makeRootClassName('SubmitReportPage');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  isSubmitting: false,
} as const;

export function FileReportPage(props: FileReportPageProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <div className={clsx(ROOT, p.className)}>
      <div className={el`content`}>
        <ReportForm
          className={el`centered`}
          onSubmitForm={p.onFileReport}
          isSubmitting={p.isSubmitting}
        />
      </div>
    </div>
  );
}

const getSourceEnumFromString = (source: string): ReportSource => {
  const caseInsensitiveSource = source.toLowerCase();
  switch (caseInsensitiveSource) {
    case 'ledger':
      return ReportSource.PARTNER_LEDGER;
    case 'safepal':
      return ReportSource.PARTNER_SAFEPAL;
    case '1inch':
      return ReportSource.PARTNER_1INCH;
    case '1inch-android':
      return ReportSource.PARTNER_1INCH_ANDROID;
    case '1inch-ios':
      return ReportSource.PARTNER_1INCH_IOS;
    case 'blokk':
      return ReportSource.PARTNER_BLOKK;
    case 'dany':
      return ReportSource.PARTNER_DANY;
    case 'fbi':
      return ReportSource.PARTNER_FBI;
    case 'sanjoseca.gov':
      return ReportSource.PARTNER_SANJOSECA_GOV;
    case 'opp':
      return ReportSource.PARTNER_OPP;
    case 'mtv':
      return ReportSource.PARTNER_MTV;
    case 'coinbase':
      return ReportSource.PARTNER_COINBASE;
    case 'rcmp':
      return ReportSource.PARTNER_RCMP;
    case 'actionfraud':
      return ReportSource.PARTNER_ACTION_FRAUD;
    case 'nypd':
      return ReportSource.PARTNER_NYPD;
    case 'bitfly':
      return ReportSource.PARTNER_BITFLY;
    case 'solanafm':
      return ReportSource.PARTNER_SOLANAFM;
    case 'metamask':
      return ReportSource.PARTNER_METAMASK;
    case 'avascan':
      return ReportSource.PARTNER_AVASCAN;
    case 'webacy':
      return ReportSource.PARTNER_WEBACY;
    case 'shamrock':
      return ReportSource.PARTNER_SHAMROCK;
    case 'ton':
      return ReportSource.PARTNER_TON;
    case 'thesee':
      return ReportSource.PARTNER_THESEE;
    case 'vitawallet':
      return ReportSource.PARTNER_VITAWALLET;
    case 'ripio':
      return ReportSource.PARTNER_RIPIO;
    default:
      return ReportSource.CHAINABUSE;
  }
};

export const getFileReportInputFromFormValues = (
  values: SubmitReportFormValues,
  source: string
) => ({
  isPrivate: values.isPrivate,
  source: getSourceEnumFromString(source),
  addresses:
    values.addresses?.map(({ address, domain, chain, label }) => ({
      address,
      domain,
      chain,
      label,
    })) ?? [],
  transactionHashes:
    values.transactionHashes?.map(({ hash, chain, label }) => ({
      hash,
      chain,
      label,
    })) ?? [],
  lexicalSerializedDescription:
    values.lexicalSerializedDescription as unknown as JSONValue,
  scamCategory: values.category,
  losses: values.losses?.map((lossField) => ({
    asset: lossField.asset,
    amount: parseFloat(lossField.amount),
  })),
  compromiseIndicators: values.compromiseIndicators?.map(({ type, value }) => ({
    type,
    value,
  })),
  tokens: values.tokens?.map((token) => ({
    tokenId: token.tokenId,
  })),
  evidence: values.evidence.map(({ source, uploadId: fileUploadId }) => ({
    source,
    fileUploadId,
  })),
  accusedScammers: values.knownScammers,
  categoryDescription: values.otherCategoryDescription,
  ...(values.agreedToBeContacted
    ? {
      agreedToBeContactData: {
        agreed: values.agreedToBeContacted.agreed,
        agreedToBeContactedByLawEnforcement:
          values.agreedToBeContacted.agreedToBeContactedByLawEnforcement,
        name: values.agreedToBeContacted.name,
        email: values.agreedToBeContacted.email,
        phoneNumber: values.agreedToBeContacted.phoneNumber,
        country: values.agreedToBeContacted.country,
        countryCode: values.agreedToBeContacted.countryCode,
        state: values.agreedToBeContacted.state,
        stateCode: values.agreedToBeContacted.stateCode,
        city: values.agreedToBeContacted.city,
        zipCode: values.agreedToBeContacted.zipCode,
      },
    }
    : {}),
});
function FileReportPageContainer(): ReactElement {
  const { me } = useMe();

  const {
    isReady,
    push,
    query,
    query: { source },
  } = useRouter();

  /**
   * If its postAuth user was on FileReportPage before login/signup
   * this is used to show loader while report gets filed in background
   * actual logic to file report is in {@link useHandlePostAuth}
   */
  const isPostAuth =
    isReady && !!query.postAuth && query[postAuthQueryParam] !== '0';
  const initialValues: FormValuesFromReportFragment | undefined | null =
    getReportFormLocalState();

  const [fileReportMutation, { loading }] = useFileReportMutation({});
  const [fileGuestReportMutation, { loading: submittingGuestReport }] =
    useFileGuestReportMutation({});

  const popUpOnError = usePopUpOnError({
    title: 'Something went wrong',
    showContactUsLink: true,
  });

  const sourceString = typeof source === 'string' ? source : '';

  return isPostAuth && initialValues && me?.isVerified ? (
    <div className={ROOT}>
      <div className={el`loader-wrapper`}>
        <ScamReportDetailsLoading />
      </div>
    </div>
  ) : (
    <FileReportPage
      className={el`file-report-page`}
      isSubmitting={loading || submittingGuestReport}
      onFileReport={async (values) => {
        const input = getFileReportInputFromFormValues(values, sourceString);
        if (me) {
          const response = await popUpOnError(fileReportMutation, {
            variables: {
              input,
            },
            optimisticResponse: {
              fileReport: {
                __typename: 'FileReportPayload',
                success: true,
                report: {
                  ...input,
                  id: 'temp-report-id',
                  createdAt: new Date().toISOString(),
                  biDirectionalVoteCount: 1,
                  reportedBy: me,
                  commentsCount: 0,
                  source: getSourceEnumFromString(sourceString),
                  addresses: input.addresses.map((a, index) => ({
                    ...a,
                    id: `temp-address-id-${index}`,
                  })),
                  compromiseIndicators: (input.compromiseIndicators ?? [])?.map(
                    (c, index) => ({
                      id: `temp-compromise-indicator-id-${index}`,
                      type: c.type,
                      value: c.value,
                    })
                  ),
                  tokens: (input.tokens ?? [])?.map((t, index) => ({
                    id: `temp-token-${index}`,
                    tokenId: t.tokenId,
                  })),
                  transactionHashes: (
                    input.transactionHashes ?? []
                  )?.map((t, index) => ({
                    ...t,
                    id: `temp-transaction-hash-${index}`,
                  })),
                  accusedScammers: input.accusedScammers.map(
                    (s, scammerIndex) => ({
                      ...s,
                      id: `temp-known-scammer-id-${scammerIndex}`,
                      info: s.info.map((c, contactIndex) => ({
                        ...c,
                        id: `temp-scammer-info-id-${scammerIndex}-${contactIndex}`,
                      })),
                    })
                  ),
                },
              },
            },
          });

          if (response) {
            const { data } = response;
            if (data?.fileReport.report) {
              trackGTMEvent(GTMEvent.SCAM_REPORT_FILED);
              push({
                pathname: getRouteForEditReport(data.fileReport.report.id),
                query: { submitted: true },
              });
              clearReportFormLocalState();
            }
          }
        } else {
          /**
           * file report as guest
           */
          const response = await popUpOnError(fileGuestReportMutation, {
            variables: {
              input,
            },
          });

          if (response) {
            const { data } = response;
            if (data?.fileGuestReport.report) {
              trackGTMEvent(GTMEvent.SCAM_REPORT_FILED);
              push({
                pathname: getRouteForReport(data.fileGuestReport.report.id),
                query: { submitted: true },
              });
              clearReportFormLocalState();
            }
          }
        }
      }}
    />
  );
}

export default FileReportPageContainer;
