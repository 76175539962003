import { ChainType, isChainDiscoverable } from '@/types/chain';
import {
  isADAAddress,
  isBTCAddress,
  isETHAddress,
  isHBARAddress,
  isLTCAddress,
  isSOLAddress,
  isTRXAddress,
  isAVAXAddress,
  isMVXAddress,
  isATTNAddress,
  isALGOAddress,
  isBASEAddress,
  isTonAddress,
} from './isAddress';
import {
  isADATransactionHash,
  isALGOTransactionHash,
  isATTNTransactionHash,
  isAVAXTransactionHash,
  isBASETransactionHash,
  isBTCTransactionHash,
  isETHTransactionHash,
  isHBARTransactionHash,
  isLTCTransactionHash,
  isMVXTransactionHash,
  isSOLTransactionHash,
  isTRXTransactionHash,
  isTONTransactionHash,
} from './isTransactionHash';

/**
 * For a given @param {string} address, @returns chains that are not
 * applicable to the address
 */
export const getDisabledChains = (address: string): ChainType[] => {
  const disabled = [];
  if (!isBTCAddress(address)) {
    disabled.push(ChainType.BTC);
  }

  if (!isETHAddress(address)) {
    disabled.push(
      ChainType.ETH,
      ChainType.BINANCE,
      // ChainType.CELO
      ChainType.POLYGON
    );
  }

  if (!isHBARAddress(address)) {
    disabled.push(ChainType.HBAR);
  }

  if (!isTRXAddress(address)) {
    disabled.push(ChainType.TRON);
  }

  if (!isSOLAddress(address)) {
    disabled.push(ChainType.SOL);
  }

  if (!isADAAddress(address)) {
    disabled.push(ChainType.CARDANO);
  }

  if (!isLTCAddress(address)) {
    disabled.push(ChainType.LITECOIN);
  }

  if (!isAVAXAddress(address)) {
    disabled.push(ChainType.AVALANCHE);
  }

  if (!isMVXAddress(address)) {
    disabled.push(ChainType.MULTIVERSX);
  }

  if (!isATTNAddress(address)) {
    disabled.push(ChainType.ARBITRUM);
  }

  if (!isALGOAddress(address)) {
    disabled.push(ChainType.ALGORAND);
  }

  if (!isBASEAddress(address)) {
    disabled.push(ChainType.BASE);
  }
  if (!isTonAddress(address)) {
    disabled.push(ChainType.TON);
  }

  return disabled;
};

export const getDisabledChainsFromHash = (hash: string): ChainType[] => {
  const disabled = [];
  if (!isBTCTransactionHash(hash)) {
    disabled.push(ChainType.BTC);
  }

  if (!isETHTransactionHash(hash)) {
    disabled.push(
      ChainType.ETH,
      ChainType.BINANCE,
      // ChainType.CELO
      ChainType.POLYGON
    );
  }

  if (!isHBARTransactionHash(hash)) {
    disabled.push(ChainType.HBAR);
  }

  if (!isTRXTransactionHash(hash)) {
    disabled.push(ChainType.TRON);
  }

  if (!isSOLTransactionHash(hash)) {
    disabled.push(ChainType.SOL);
  }

  if (!isADATransactionHash(hash)) {
    disabled.push(ChainType.CARDANO);
  }

  if (!isLTCTransactionHash(hash)) {
    disabled.push(ChainType.LITECOIN);
  }

  if (!isAVAXTransactionHash(hash)) {
    disabled.push(ChainType.AVALANCHE);
  }

  if (!isMVXTransactionHash(hash)) {
    disabled.push(ChainType.MULTIVERSX);
  }

  if (!isATTNTransactionHash(hash)) {
    disabled.push(ChainType.ARBITRUM);
  }

  if (!isALGOTransactionHash(hash)) {
    disabled.push(ChainType.ALGORAND);
  }

  if (!isBASETransactionHash(hash)) {
    disabled.push(ChainType.BASE);
  }
  if (!isTONTransactionHash(hash)) {
    disabled.push(ChainType.TON);
  }

  return disabled;
};

/**
 * Return possible chains for a given address
 * @param {string} address,
 * @param {boolean} includeUndiscoverableChains - Whether or not to include
 * chains that are actively being promoted in the product,
 * @default false
 * @returns {array} - list of possible chains
 */
export const getPossibleChains = (
  address: string,
  includeUndiscoverableChains = false
): ChainType[] => {
  const disabled = getDisabledChains(address);

  return Object.values(ChainType).filter(
    (type) =>
      !disabled.includes(type) &&
      (includeUndiscoverableChains || isChainDiscoverable(type))
  );
};

export const getPossibleChainsFromHash = (hash: string): ChainType[] => {
  const disabled = getDisabledChainsFromHash(hash);

  return Object.values(ChainType).filter((type) => !disabled.includes(type));
};

export const getChainsOrderedByReports = (
  getReportedCountForChain: (chain: ChainType) => number
): ChainType[] => {
  const chainsList: Record<ChainType, number> = {
    BTC: 0,
    ETH: 0,
    TRON: 0,
    SOL: 0,
    POLYGON: 0,
    BINANCE: 0,
    HBAR: 0,
    CARDANO: 0,
    LITECOIN: 0,
    AVALANCHE: 0,
    MULTIVERSX: 0,
    ARBITRUM: 0,
    ALGORAND: 0,
    BASE: 0,
    TON:0,
  };

  Object.values(ChainType).forEach((chain) => {
    chainsList[ChainType[chain]] = getReportedCountForChain(chain);
  });

  return Object.values(ChainType).sort((a, b) => {
    return chainsList[b] - chainsList[a];
  });
};
