import { SEO_URL_PREFIX } from '@/features';
import { ChainKind as ChainType } from '@/generated/graphql';
export { ChainType };

const IMAGES_PATH = '/images/chains';

export const getDisplayStringForChainType = (chainType: ChainType): string => {
  switch (chainType) {
    case ChainType.BTC:
      return 'Bitcoin';
    case ChainType.ETH:
      return 'Ethereum';
    case ChainType.TRON:
      return 'Tron';
    case ChainType.SOL:
      return 'Solana';
    case ChainType.BINANCE:
      return 'Binance Smart Chain';
    // case ChainType.CELO:
    //   return 'Celo';
    case ChainType.HBAR:
      return 'Hedera';
    case ChainType.POLYGON:
      return 'Polygon';
    case ChainType.CARDANO:
      return 'Cardano';
    case ChainType.LITECOIN:
      return 'Litecoin';
    case ChainType.AVALANCHE:
      return 'Avalanche (0x)';
    case ChainType.MULTIVERSX:
      return 'MultiversX';
    case ChainType.ARBITRUM:
      return 'Arbitrum';
    case ChainType.ALGORAND:
      return 'Algorand';
    case ChainType.BASE:
      return 'Base';
    case ChainType.TON:
      return 'Ton';
    default:
      throw new Error(`Unrecognized chain type: ${chainType}`);
  }
};

export const getTickerForChain = (type: ChainType): string => {
  switch (type) {
    case ChainType.BTC:
      return 'BTC';
    case ChainType.ETH:
      return 'ETH';
    case ChainType.TRON:
      return 'TRX';
    case ChainType.SOL:
      return 'SOL';
    case ChainType.BINANCE:
      return 'BSC';
    // case ChainType.CELO:
    //   return 'CELO';
    case ChainType.HBAR:
      return 'HBAR';
    case ChainType.POLYGON:
      return 'MATIC';
    case ChainType.CARDANO:
      return 'ADA';
    case ChainType.LITECOIN:
      return 'LTC';
    case ChainType.AVALANCHE:
      return 'AVAX';
    case ChainType.MULTIVERSX:
      return 'MVX';
    case ChainType.ARBITRUM:
      return 'ATTN';
    case ChainType.ALGORAND:
      return 'ALGO';
    case ChainType.BASE:
      return 'BASE';
    case ChainType.TON:
      return 'TON';
    default:
      throw new Error(`Unrecognized chain type: ${type}`);
  }
};

export const getImageForChain = (type: ChainType): string => {
  switch (type) {
    case ChainType.BTC:
      return `${IMAGES_PATH}/btc.svg`;
    case ChainType.ETH:
      return `${IMAGES_PATH}/eth.svg`;
    case ChainType.TRON:
      return `${IMAGES_PATH}/tron.svg`;
    case ChainType.SOL:
      return `${IMAGES_PATH}/solana.svg`;
    case ChainType.BINANCE:
      return `${IMAGES_PATH}/binance.svg`;
    // case ChainType.CELO:
    //   return `${IMAGES_PATH}/celo.svg`;
    case ChainType.HBAR:
      return `${IMAGES_PATH}/hbar.svg`;
    case ChainType.POLYGON:
      return `${IMAGES_PATH}/polygon.svg`;
    case ChainType.CARDANO:
      return `${IMAGES_PATH}/cardano.svg`;
    case ChainType.LITECOIN:
      return `${IMAGES_PATH}/litecoin.svg`;
    case ChainType.AVALANCHE:
      return `${IMAGES_PATH}/avalanche.svg`;
    case ChainType.MULTIVERSX:
      return `${IMAGES_PATH}/multiversx.svg`;
    case ChainType.ARBITRUM:
      return `${IMAGES_PATH}/arbitrum.svg`;
    case ChainType.ALGORAND:
      return `${IMAGES_PATH}/algorand.svg`;
    case ChainType.BASE:
      return `${IMAGES_PATH}/base.svg`;
    case ChainType.TON:
      return `${IMAGES_PATH}/ton.svg`;
    default:
      throw new Error(`Unrecognized chain type: ${type}`);
  }
};

export const getOpenGraphImageForChain = (chain: ChainType): string => {
  switch (chain) {
    case ChainType.BTC:
      return `${SEO_URL_PREFIX}/btc.png`;
    case ChainType.ETH:
      return `${SEO_URL_PREFIX}/eth.png`;
    case ChainType.TRON:
      return `${SEO_URL_PREFIX}/tron.png`;
    case ChainType.SOL:
      return `${SEO_URL_PREFIX}/sol.png`;
    case ChainType.BINANCE:
      return `${SEO_URL_PREFIX}/binance.png`;
    // case ChainType.CELO:
    //   return `${SEO_URL_PREFIX}/celo.png`;
    case ChainType.HBAR:
      return `${SEO_URL_PREFIX}/hbar.png`;
    case ChainType.POLYGON:
      return `${SEO_URL_PREFIX}/polygon.png`;
    case ChainType.CARDANO:
      return `${SEO_URL_PREFIX}/cardano.png`;
    case ChainType.LITECOIN:
      return `${SEO_URL_PREFIX}/litecoin.png`;
    case ChainType.AVALANCHE:
      return `${SEO_URL_PREFIX}/avalanche.png`;
    case ChainType.MULTIVERSX:
      return `${SEO_URL_PREFIX}/multiversx.png`;
    case ChainType.ARBITRUM:
      return `${SEO_URL_PREFIX}/arbitrum.png`;
    case ChainType.ALGORAND:
      return `${SEO_URL_PREFIX}/algorand.png`;
    case ChainType.BASE:
      return `${SEO_URL_PREFIX}/base.png`;
    case ChainType.TON:
      return `${SEO_URL_PREFIX}/ton.png`;
    default:
      throw new Error(`Unrecognized chain type: ${chain}`);
  }
};

/**
 * These are the chains that provide discoverable reports throughout the
 * product. For more information see [#526](https://github.com/trmlabs/trm-chainabuse/issues/526) and [#624](https://github.com/trmlabs/trm-chainabuse/issues/634).
 */
const discoverableChains = [
  ChainType.BTC,
  ChainType.ETH,
  ChainType.SOL,
  ChainType.TRON,
  ChainType.BINANCE,
  ChainType.HBAR,
  ChainType.POLYGON,
  ChainType.CARDANO,
  ChainType.LITECOIN,
  ChainType.AVALANCHE,
  ChainType.MULTIVERSX,
  ChainType.ARBITRUM,
  ChainType.ALGORAND,
  ChainType.BASE,
  ChainType.TON,
];

export const isChainDiscoverable = (chain: ChainType): boolean => {
  return discoverableChains.includes(chain);
};

/**
 * Props controlling whether or not we want to show chains that are not
 * discoverable, a concept that represents whether a chain should be shown in
 * UIs primarily geared towards discovery, vs creation or updates.
 */
export type ChainDiscoverabilityProps = {
  /**
   * Whether or not to include chains that are actively being promoted in the
   * product.
   * For more information {@see https://github.com/trmlabs/trm-chainabuse/issues/526}
   *
   * @default {false}
   */
  includeUndiscoverableChains?: boolean;
};
